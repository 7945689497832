<template>
	<div class="studentsList">
		<div class="loading" v-if="loading">
			<van-loading size="54" color="#1989fa" />
		</div>
		<div v-else>
			<div class="topSearch" @click="addStudent">
				<img src="../assets/imgs/add.png" alt="" />
				<span>添加学生</span>
			</div>
			<div class="studentsList-list" v-if="status">
				<div
					class="item"
					v-for="(item, index) in list"
					:key="index"
					@click="toPage(item.id)"
				>
					<div style="display: flex; align-items: center">
						<div class="left-number">
							{{ returnFloat(index + 1, 3) }}
						</div>
						<div class="left">
							<div class="left-name">
								{{ item.name }}
								<div class="right">{{ item.mobile }}</div>
							</div>
							<div class="left-school">
								<div>
									{{
										item.school ? item.school : '未填写学校'
									}}
								</div>
								<div>
									{{ item.class ? item.class : '未填写班级' }}
								</div>
							</div>
						</div>
					</div>
					<div class="product">
						<div>{{ item.goodNums }}个产品</div>
						<img src="../assets/imgs/product.png" alt="" />
					</div>
				</div>
			</div>
			<div class="empty" v-else>
				<van-empty image-size="200" image="search" />
				<div
					style="
						text-align: center;
						margin: 0 auto;
						font-size: 13px;
						color: #999999;
					"
				>
					当前没有绑定学生
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { students } from '../api/index'
export default {
	data() {
		return {
			status: true,
			loading: true,
			list: null
		}
	},
	mounted() {
		let that = this
		students({
			openId: localStorage.openID
		}).then(res => {
			if (res.data.code == 200) {
				that.list = res.data.data
				if (that.list.length == 0) {
					this.status = false
				}
			} else {
				that.$toast({
					message: res.data.msg,
					icon: 'none'
				})
			}

			this.loading = false
		})
	},
	methods: {
		addStudent() {
			this.$router.push('/bindStudent')
		},
		toPage(id) {
			this.$router.push('/productList?id=' + id)
		},
		returnFloat(n, m) {
			var _a = (Array(m).join(0) + n).slice(-m)
			return _a
		}
	}
}
</script>

<style lang="scss" scoped>
.studentsList {
	.topSearch {
		width: 100%;
		height: 90px;
		display: flex;
		flex-direction: row;
		align-items: center;
		background-color: #f5f5f5;
		img {
			height: 35px;
			width: 40px;
			padding-left: 30px;
		}
		span {
			margin-left: 20px;
			font-size: 25px;
		}
	}
	width: 100%;
	&-list {
		margin-top: 20px;
		width: 100%;
		.item {
			border-bottom: 1px solid #ececec;
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: space-between;
			padding: 15px 35px;
			height: 130px;
			.left {
				display: flex;
				flex-direction: column;
				&-number {
					color: #ababab;
					font-size: 30px;
					font-weight: 400;
				}
				&-name {
					display: flex;
					flex-direction: row;
					align-items: center;
					font-size: 30px;
					margin-left: 30px;
					.right {
						margin-left: 20px;
						color: #999999;
					}
				}
				&-school {
					display: flex;
					flex-direction: row;
					margin-top: 20px;
					font-size: 23px;
					margin-left: 30px;
					> div {
						margin-right: 20px;
						color: #999999;
					}
					margin-bottom: 10px;
				}
			}
			.right {
				font-size: 25px;
			}
			.product {
				display: flex;
				flex-direction: column;
				align-items: center;
				img {
					margin-top: 10px;
					height: 35px;
					width: 35px;
				}
				font-size: 25px;
				color: #999999;
			}
		}
		.empty {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			width: 100%;
		}
	}
}
.loading {
	width: 100vw;
	height: 100vh;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
}
</style>
