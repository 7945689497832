<template>
	<div id="app">
		<div v-if="loading">
			<router-view :key="key"></router-view>
		</div>
	</div>
</template>

<script>
// 判断是否为微信环境
const isWechat = () => {
	return (
		String(navigator.userAgent.toLowerCase().match(/MicroMessenger/i)) ===
		'micromessenger'
	)
}
import { getOpenID } from './api/index'
export default {
	name: 'App',
	data() {
		return {
			loading: false
		}
	},
	mounted() {
		// localStorage.openID = 'oKD6Q6sUJkIOG9CSnDouNlHJCO3A'
		if (!localStorage.openID) {
			this.getWechatCode()
		} else {
			this.loading = true
		}
	},
	computed: {
		key() {
			return this.$route.path + Math.random()
		}
	},
	methods: {
		getWechatCode() {
			let that = this
			if (isWechat) {
				let appid = 'wx62336e622d073f7a' //微信APPid
				let code = this.getUrlCode().code //是否存在code
				let local = window.location.href
				if (code == null || code === '') {
					//不存在就打开上面的地址进行授权
					window.location.href =
						'https://open.weixin.qq.com/connect/oauth2/authorize?appid=' +
						appid +
						'&redirect_uri=' +
						encodeURIComponent(local) +
						'&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect'
				} else {
					getOpenID({
						code: code
					}).then(res => {
						console.log(res.data, res.data.status, res.data.data)
						if (res.data.status != 'success') {
							that.$toast({
								message: res.data.msg,
								icon: 'none'
							})
						} else {
							this.loading = true
							localStorage.openID = res.data.data.openid
						}
					})
				}
			}
		},
		getUrlCode() {
			// 截取url中的code方法
			var url = location.search
			var theRequest = new Object()
			if (url.indexOf('?') != -1) {
				var str = url.substr(1)
				var strs = str.split('&')
				for (var i = 0; i < strs.length; i++) {
					theRequest[strs[i].split('=')[0]] = strs[i].split('=')[1]
				}
			}
			return theRequest
		}
	}
}
</script>

<style lang="scss"></style>
